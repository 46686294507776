
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  applications,
  applicationRequests,
  roleMappings,
  roles,
  applicationCreateRequests,
  platformConfigs,
} from '../../resources';
import {Form} from 'element-ui';
import {getUserId, uploadImg} from '../../api/publicMethod';
import {ObjectId} from 'bson';
import {Application} from '@/externals/MaxCI-Application-v1';
import {PlatformConfig} from '@/externals/MaxCI-PlatformConfig-v1';
import {AxiosError} from 'axios';

interface Applist extends Application {
  bg?: string;
}

@Component({
  name: 'home',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private title = '';
  private action = '';
  private imgData = {};
  private formData = {
    name: '',
    logo: '',
    description: '',
    mallService: '',
    agencyService: '',
    IDPhotoService: '',
  };
  private rules = {
    name: [{required: true, message: '请输入应用名称', trigger: 'blur'}],
    logo: [{required: true, message: '请上传应用图标', trigger: 'blur'}],
  };
  private dialogAdd = false;
  private total = 0;
  private list: Array<Applist> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {name: ''};
  private submitFlag = false; //提交开关
  private userId: ObjectId = getUserId();
  private platformConfig: null | PlatformConfig = null; //平台配置信息
  private roleName = localStorage.getItem('roleName');
  private operateId: null | ObjectId = null;

  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.updateList();
    //查询标签数据
    try {
      //查询平台信息
      this.platformConfig =
        (await platformConfigs.find(stage => stage)).find(() => true) ?? null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //跳转详情
  toDetail(id: ObjectId, name: string) {
    this.$store.state.applicationId = '';
    if (name === '平台应用') {
      this.$router.push({
        path: '/application/' + id.toHexString() + '/user/index',
      });
    } else {
      this.$router.push({
        path: '/application/' + id.toHexString() + '/overview/index',
      });
    }
  }
  //拼接图片
  get image() {
    let imageUrl = '';
    if (this.formData.logo) {
      imageUrl = this.platformConfig?.spec.downloadUrl + this.formData.logo;
    }
    return imageUrl;
  }
  //图片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successAvatar(response: any) {
    this.formData.logo = response.file;
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeAvatarUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPG = isJPEG || isPNG; //限制图片格式为jpg / png
    if (!isPG) {
      this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.spec.channels[0].channelId,
          this.platformConfig.spec.applicationId,
          false,
          [
            {
              type: '缩放',
              mode: 'fill',
              width: 200,
              height: 200,
            },
          ],
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
  // 添加保存
  private async addSumbit() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          this.submitFlag = true;
          const data = {
            userId: this.userId,
            name: this.formData.name,
            logo: this.formData.logo,
            description: this.formData.description,
            service: '商城' as
              | '商城'
              | '配送'
              | '跑腿'
              | '团购'
              | '证件照'
              | '二手',
          };
          if (this.title === '新增应用') {
            const applicationCreateRequest =
              await applicationCreateRequests.create(
                [
                  {
                    spec: data,
                  },
                ],
                {
                  watch: {
                    filter: filter =>
                      filter(
                        f => f('operationType'),
                        e => e.$eq('update'),
                      )(
                        f => f('fullDocument')('status')('phase'),
                        e => e.$exists(true),
                      ),
                  },
                  fullResource: true,
                },
              );
            if (applicationCreateRequest[0].status?.phase === '成功') {
              this.$message.success('保存成功');
              this.dialogAdd = false;
              this.updateList();
            } else {
              this.$message.error(
                applicationCreateRequest[0].status?.conditions[0].message ?? '',
              );
            }
          } else {
            const applicationRequest = await applicationRequests.create(
              [
                {
                  spec: {
                    type: '更新应用',
                    applicationId: this.operateId as ObjectId,
                    name: this.formData.name,
                    logo: this.formData.logo,
                    description: this.formData.description,
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            );
            if (applicationRequest[0].status?.phase === '成功') {
              this.$message.success('保存成功');
              this.dialogAdd = false;
              this.updateList();
            } else {
              if (
                applicationRequest[0].status?.conditions[0].message ===
                'Request failed with status code 500'
              ) {
                this.$message.error('应用名称不能重复');
              } else {
                this.$message.error(
                  applicationRequest[0].status?.conditions[0].message ?? '',
                );
              }
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('名称不能重复');
            }
          }
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  // 添加弹窗
  private addPopUps(type: string, index: number) {
    this.dialogAdd = true;
    this.title = type + '应用';
    if (type === '修改') {
      this.operateId = this.list[index]._id;
      this.formData.name = this.list[index].spec.name;
      this.formData.logo = this.list[index].spec?.logo ?? '';
      this.formData.description = this.list[index].spec?.description ?? '';
    }
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查询列表
  private async updateList() {
    const role = await roles.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('name'),
          e => e.$in(['应用管理员', '平台管理员']),
        ),
      ),
    );

    const list = await roleMappings.find(stage =>
      stage.$match(match => {
        if (this.userId) {
          match(
            f => f('spec')('userId'),
            e => e.$eq(this.userId),
          );
        }
        match(
          f => f('spec')('roleId'),
          e => e.$in(role.map(v => v._id)),
        );
        return match;
      }),
    );
    const appIds = list.map(v => {
      if (v.spec.scopeId) {
        return v.spec.scopeId;
      } else {
        return this.platformConfig?.spec.applicationId;
      }
    }) as Array<ObjectId>;
    let application = [] as Array<Applist>;
    if (appIds.length > 0) {
      application = await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$in(appIds),
          );
          if (this.searchData.name) {
            match(
              f => f('spec')('name'),
              e => e.$regex(new RegExp(this.searchData.name)),
            );
          }

          return match;
        }),
      );
      application.forEach((item, index) => {
        const num = (index + 1) % 9;
        item.bg = require('../../assets/application/bg' + (num + 1) + '.png');
      });
    }

    this.list = application;
  }
}
