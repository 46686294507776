
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {users} from '../../resources';
import {getUserId} from '../../api/publicMethod';
import {ObjectId} from 'bson';
import Application from './application.vue';
import DataCenter from './data-center.vue';

@Component({
  name: 'home',
  components: {
    Pagination,
    Application,
    DataCenter,
  },
})
export default class extends Vue {
  private menu = '';
  private menuIndex = 0;
  private menuList: Array<string> = ['应用', '数据中心'];
  private userName = '';
  private userId: ObjectId = getUserId();

  async created() {
    this.menu = this.$route.query.menu as string;
    if (this.menu === '数据中心') {
      this.menuIndex = 1;
    }
    this.$store.state.applicationId = this.$route.params.applicationId;
    //查询标签数据
    try {
      //查询用户信息
      const user = (
        await users.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.userId),
            ),
          ),
        )
      ).find(() => true);
      this.userName = user?.spec.name ?? '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private changeMenu(index: number) {
    this.menuIndex = index;
    this.$router.push({
      query: {menu: this.menuList[index]},
    });
    this.menu = this.menuList[index];
  }
  //退出登录
  private logout() {
    localStorage.setItem('roleName', '');
    localStorage.setItem('downloadUrl', '');
    localStorage.setItem('refreshToken', '');
    localStorage.setItem('accessToken', '');
    this.$router.push('/login');
  }
}
