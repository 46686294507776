
import {Component, Vue} from 'vue-property-decorator';
import moment from 'moment';
import {orders, users, shops, roles, roleMappings} from '../../resources';
import {ObjectId} from 'bson';
import * as echarts from 'echarts';
import lodash from 'lodash';
import {getUserId} from '../../api/publicMethod';

interface SummaryData {
  todayNum: number;
  lastNum?: number;
  rate?: number | string;
  totalNum: number;
}
@Component({
  name: 'overview',
})
export default class extends Vue {
  private listLoading = false;
  private time = '6';
  private updateTime = ''; //更新时间
  private transactionAmount: null | SummaryData = null; //交易金额
  private transactionNum: null | SummaryData = null; //交易订单数
  private userNum: null | SummaryData = null; //用户数
  private businessShop: null | SummaryData = null; //营业中的店铺数
  private shopSalesRanking: Array<{
    _id: {shopId: ObjectId};
    amount: number;
  }> = []; //店铺排行榜
  private searchData = {
    time: [
      moment({hour: 0})
        .subtract({day: Number(6)})
        .toDate(),
      moment({hour: 0}).toDate(),
    ],
  };
  private applicationIds: Array<ObjectId> = [];
  private xData: Array<string> = [];
  private yUser: Array<number> = [];
  private yCount: Array<number> = [];
  private userId: ObjectId = getUserId();
  private platformFlag = false;
  async created() {
    this.updateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.listLoading = true;
    const role = await roles.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('name'),
          e => e.$in(['应用管理员', '平台管理员']),
        ),
      ),
    );
    const list = await roleMappings.find(stage =>
      stage.$match(match => {
        if (this.userId) {
          match(
            f => f('spec')('userId'),
            e => e.$eq(this.userId),
          );
        }
        match(
          f => f('spec')('roleId'),
          e => e.$in(role.map(v => v._id)),
        );
        return match;
      }),
    );
    const appIds = [] as Array<ObjectId>;
    this.platformFlag = false;
    for (const item of list) {
      if (item.spec.scopeId) {
        appIds.push(item.spec.scopeId);
      } else {
        this.platformFlag = true;
        break;
      }
    }
    if (!this.platformFlag) {
      this.applicationIds = appIds;
    }
    if (!this.platformFlag && this.applicationIds.length === 0) {
      this.listLoading = false;
      return;
    }
    await this.checkSummary(); //查询今日交易金额 交易订单数 新增用户数
    await this.checkBusinessShop(); //查询营业中的店铺数
    await this.checkCharts(); //查询图表
    await this.checkSalesRanking(); //店铺销售额排名
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  mounted() {
    const echartbox = document.getElementById('echartbox') as HTMLCanvasElement;
    window.onresize = () => {
      return (() => {
        echarts.init(echartbox).resize();
      })();
    };
  }
  //获取店铺名称
  private getShopName(id: ObjectId) {
    let name = '--';
    if (id) {
      name = this.shopInfoData[id.toHexString()].spec.name;
    }
    return name;
  }
  //查询图表
  private async checkCharts() {
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              if (this.applicationIds.length > 0) {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$in(this.applicationIds),
                );
              }
              query(
                f => f('status')('payInfo'),
                e => e.$exists(true),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e => e.$gte(moment(this.searchData.time[0]).toDate()),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .add({day: 1})
                        .subtract({millisecond: 1})
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('chartsData', chartsStage =>
            chartsStage
              .$group(
                e =>
                  e.$object(object =>
                    object('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    ),
                  ),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              )
              .$sort(sort => sort(f => f('_id')('date'), '升序')),
          ),
        ),
    );
    this.xData = list[0].chartsData.map(v => v._id.date as string);
    this.yUser = list[0].chartsData.map(v => v.user / 100);
    this.yCount = list[0].chartsData.map(v => v.count);
    const chart = document.getElementById('echartbox') as HTMLCanvasElement;
    const myChart = echarts.init(chart);
    // 绘制图表
    myChart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        data: this.xData,
        max: this.xData[this.xData.length - 1],
        axisLabel: {
          showMaxLabel: true,
        },
        boundaryGap: false,
      },
      yAxis: [
        {
          type: 'value',
          name: '交易金额',
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
          axisLabel: {
            show: false, //隐藏值
          },
          position: 'left',
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
            },
          },
        },
        {
          type: 'value',
          name: '订单数',
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
          axisLabel: {
            show: false, //隐藏值
          },
          position: 'right',
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
            },
          },
        },
      ],
      legend: {
        orient: 'horizontal',
        padding: [0, 0, 10, 0],
        data: ['交易金额', '订单数'],
      },
      series: [
        {
          name: '交易金额',
          data: this.yUser,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 0,
          itemStyle: {
            color: '#6DD181',
          },
        },
        {
          name: '订单数',
          data: this.yCount,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 1,
          itemStyle: {
            color: '#657AFF',
          },
        },
      ],
    });
  }
  //店铺销售额排名
  private async checkSalesRanking() {
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              if (this.applicationIds.length > 0) {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$in(this.applicationIds),
                );
              }
              query(
                f => f('status')('payInfo'),
                e => e.$exists(true),
              )(
                f => f('spec')('type'),
                e => e.$eq('商城'),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e => e.$gte(moment(this.searchData.time[0]).toDate()),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .add({day: 1})
                        .subtract({millisecond: 1})
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('summaryData', summaryStage =>
            summaryStage
              .$group(
                e =>
                  e.$object(object =>
                    object('shopId', e =>
                      e.$fieldPath(f => f('spec')('shopId')),
                    ),
                  ),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('shopRefundAmount', e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$eq(
                            e => e.$fieldPath(f => f('spec')('type')),
                            e => e.$literal('商城'),
                          ),
                        _then =>
                          _then.$fieldPath(f => f('spec')('refund')('user')),
                        _else => _else.$literal(0),
                      ),
                    ),
                  )('otherRefundAmount', e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$or(
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('退款'),
                                  ),
                                e =>
                                  e.$ne(
                                    e => e.$fieldPath(f => f('spec')('type')),
                                    e => e.$literal('商城'),
                                  ),
                              ),
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('取消'),
                                  ),
                                e => e.$fieldPath(f => f('status')('payInfo')),
                                e =>
                                  e.$ne(
                                    e => e.$fieldPath(f => f('spec')('type')),
                                    e => e.$literal('商城'),
                                  ),
                              ),
                          ),
                        _then =>
                          _then.$fieldPath(f => f('spec')('amount')('user')),
                        _else => _else.$literal(0),
                      ),
                    ),
                  ),
              )
              .$group(
                e =>
                  e.$object(object =>
                    object('shopId', e =>
                      e.$fieldPath(f => f('_id')('shopId')),
                    ),
                  ),
                group =>
                  group('amount', e =>
                    e.$sum(sum =>
                      sum.$subtract(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        subtract => subtract.$fieldPath(f => f('user')),
                        subtract =>
                          subtract.$add(
                            e => e.$fieldPath(f => f('shopRefundAmount')),
                            e => e.$fieldPath(f => f('otherRefundAmount')),
                          ),
                      ),
                    ),
                  ),
              )
              .$sort(sort => sort(f => f('amount'), '降序')),
          ),
        ),
    );
    const summaryData = list[0].summaryData.filter((f, i) => i <= 4);
    let shopIdData: Array<ObjectId> = [];
    summaryData.forEach(item => {
      shopIdData.push(item._id.shopId);
    });
    shopIdData = Array.from(new Set(shopIdData));
    const shopData = await shops.find(stage =>
      stage.$match(match => {
        if (this.applicationIds.length > 0) {
          match(
            f => f('spec')('applicationId'),
            e => e.$in(this.applicationIds),
          );
        }
        match(
          f => f('_id'),
          e => e.$in(shopIdData),
        );
        return match;
      }),
    );
    this.shopInfoData = lodash.zipObject(
      shopData.map(v => v._id.toHexString()),
      shopData,
    );
    this.shopSalesRanking = summaryData;
  }
  //查询今日交易金额 交易订单数 新增用户数
  private async checkSummary() {
    const list = await orders.find(stage =>
      stage
        .$match(match => {
          if (this.applicationIds.length > 0) {
            match(
              f => f('spec')('applicationId'),
              e => e.$in(this.applicationIds),
            );
          }
          return match;
        })
        .$sort(sort => sort(f => f('metadata')('creationTimestamp'), '升序'))
        .$facet(facet =>
          facet('summaryData', summaryStage =>
            summaryStage
              .$match(match =>
                match(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                ),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('todaySummaryData', todaySummaryData =>
            todaySummaryData
              .$match(match =>
                match.$and(and => {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$gte(moment().startOf('day').toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$lte(moment().endOf('day').toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('status')('payInfo'),
                      e => e.$exists(true),
                    ),
                  );
                  return and;
                }),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('lastSummaryData', lastSummaryData =>
            lastSummaryData
              .$match(match =>
                match.$and(and => {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gte(
                          moment().subtract({day: 1}).startOf('day').toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment().subtract({day: 1}).endOf('day').toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('status')('payInfo'),
                      e => e.$exists(true),
                    ),
                  );
                  return and;
                }),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          ),
        ),
    );

    const user = await users.find(stage =>
      stage
        .$match(match => {
          if (this.applicationIds.length > 0) {
            match(
              f => f('spec')('applicationId'),
              e => e.$in(this.applicationIds),
            );
          }
          return match;
        })
        .$facet(facet =>
          facet('userNumTotalNum', countStage => countStage.$count('count'))(
            'todayNewUsers',
            todayNewUsers =>
              todayNewUsers
                .$match(match =>
                  match.$and(and => {
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e => e.$gte(moment().startOf('day').toDate()),
                      ),
                    );
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e => e.$lte(moment().endOf('day').toDate()),
                      ),
                    );
                    return and;
                  }),
                )
                .$count('count'),
          )('lastNewUsers', lastNewUsers =>
            lastNewUsers
              .$match(match =>
                match.$and(and => {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gte(
                          moment().subtract({day: 1}).startOf('day').toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment().subtract({day: 1}).endOf('day').toDate(),
                        ),
                    ),
                  );
                  return and;
                }),
              )
              .$count('count'),
          ),
        ),
    );
    //交易金额
    const transactionAmountTodayNum =
      list[0].todaySummaryData.length > 0
        ? list[0].todaySummaryData[0].user / 100
        : 0;
    const transactionAmountLastNum =
      list[0].lastSummaryData.length > 0
        ? list[0].lastSummaryData[0].user / 100
        : 0;
    //交易订单
    const transactionNumTodayNum =
      list[0].todaySummaryData.length > 0
        ? list[0].todaySummaryData[0].count
        : 0;
    const transactionNumLastNum =
      list[0].lastSummaryData.length > 0 ? list[0].lastSummaryData[0].count : 0;
    //用户人数
    const userNumTodayNum = user[0].todayNewUsers[0]
      ? user[0].todayNewUsers[0].count.valueOf()
      : 0;
    const userNumLastNum = user[0].lastNewUsers[0]
      ? user[0].lastNewUsers[0].count.valueOf()
      : 0;

    this.transactionAmount = {
      todayNum: transactionAmountTodayNum,
      lastNum: transactionAmountLastNum,
      totalNum:
        list[0].summaryData.length > 0 ? list[0].summaryData[0].user / 100 : 0,
      rate: transactionAmountLastNum
        ? (
            Number(
              (transactionAmountTodayNum * 10000 -
                transactionAmountLastNum * 10000) /
                transactionAmountLastNum,
            ) / 100
          ).toFixed(2)
        : 0,
    };
    this.transactionNum = {
      todayNum: transactionNumTodayNum,
      lastNum: transactionNumLastNum,
      totalNum:
        list[0].summaryData.length > 0 ? list[0].summaryData[0].count : 0,
      rate: transactionNumLastNum
        ? (
            Number(
              (transactionNumTodayNum * 10000 - transactionNumLastNum * 10000) /
                transactionNumLastNum,
            ) / 100
          ).toFixed(2)
        : 0,
    };
    this.userNum = {
      todayNum: userNumTodayNum,
      lastNum: userNumLastNum,
      totalNum: user[0].userNumTotalNum[0]
        ? user[0].userNumTotalNum[0].count.valueOf()
        : 0,
      rate: userNumLastNum
        ? (
            Number(
              (userNumTodayNum * 10000 - userNumLastNum * 10000) /
                userNumLastNum,
            ) / 100
          ).toFixed(2)
        : 0,
    };
    this.listLoading = false;
  }
  //查询营业中的店铺数
  private async checkBusinessShop() {
    const list = await shops.find(stage =>
      stage
        .$match(match => {
          if (this.applicationIds.length > 0) {
            match(
              f => f('spec')('applicationId'),
              e => e.$in(this.applicationIds),
            );
          }
          return match;
        })
        .$facet(facet =>
          facet('totalNum', countStage => countStage.$count('count'))(
            'todayNum',
            countStage =>
              countStage
                .$match(match =>
                  match.$and(and => {
                    and(query =>
                      query(
                        f => f('spec')('service'),
                        e => e.$eq('开业'),
                      )(
                        f => f('spec')('serviceTime')('times')('begin'),
                        e => e.$lte(moment().format('HH:mm')),
                      )(
                        f => f('spec')('serviceTime')('times')('end'),
                        e => e.$gte(moment().format('HH:mm')),
                      ),
                    );
                    return and;
                  }),
                )
                .$count('count'),
          ),
        ),
    );
    this.businessShop = {
      todayNum: list[0].todayNum[0] ? list[0].todayNum[0].count.valueOf() : 0,
      totalNum: list[0].totalNum[0] ? list[0].totalNum[0].count.valueOf() : 0,
    };
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkCharts();
    this.checkSalesRanking();
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.checkCharts();
    this.checkSalesRanking();
  }
}
